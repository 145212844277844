// Entry point for the build script in your package.json
import "./channels";
import "@hotwired/turbo-rails";
import './add_jquery';
import * as bootstrap from "bootstrap";
require("@nathanvda/cocoon");


window.Cookies = require("js-cookie");


document.addEventListener("turbo:load", () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  //Add sticky popovers
  $(".pop").popover({
    trigger: "manual",
    html: true,
    animation: false,
    placement: 'bottom'
  })
  .on("mouseenter", function() {
    var _this = this;
    $(this).popover("show");
    $(".popover").on("mouseleave", function() {
      $(_this).popover('hide');
    });
  })
  .on("mouseleave", function() {
    var _this = this;
    setTimeout(function() {
      if (!$(".popover:hover").length) {
        $(_this).popover("hide");
      }
    }, 50);
  });
});

document.addEventListener("turbo:frame-render", function(e) {
  $(e.target).find(".cardpop").popover({
    trigger: "manual",
    html: true,
    animation: false,
    placement: 'bottom'
  })
  .on("mouseenter", function() {
    var _this = this;
    $(this).popover("show");
    $(".popover").on("mouseleave", function() {
      $(_this).popover('hide');
    });
  })
  .on("mouseleave", function() {
    var _this = this;
    setTimeout(function() {
      if (!$(".popover:hover").length) {
        $(_this).popover("hide");
      }
    }, 50);
  });
})


